import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { isValidEmail, addToMailChimp } from '../../helpers/utils';

export const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscribe = async () => {
    if (!email) {
      setMessage('Please enter your email.');
    } else if (!isValidEmail(email)) {
      setMessage('Please enter a valid email.');
    } else {
      try {
        const response = await addToMailChimp({ email }, ['Subscriber']);
        if (response.success) {
          setMessage('Subscription successful!');
        } else {
          setMessage(`Subscription failed: ${response.message}`);
        }
      } catch (error) {
        setMessage('Subscription failed. Please try again.');
        console.error(error);
      }
    }
  };

  return (
    <div className='subscribe-section'>
      <p className='subtitle bold mb-10' style={{ color: 'white' }}>
        Subscribe to my email newsletter
      </p>
      <p className='footer-link mb-20'>
        Stay ahead with exclusive access to new releases, special offers, tips,
        advice, inspiration, and more!
      </p>
      <div className='flex'>
        <Input
          style={{ height: '50px', marginBottom: '10px' }}
          placeholder='Enter your email'
          onChange={(e) => setEmail(e.target.value)}
          width='100%'
        />
        <Button
          onClick={() => handleSubscribe()}
          style={{ height: '50px' }}
          size='large'
          type='primary'
        >
          Subscribe
        </Button>
      </div>
      <p className='subtitle' style={{ color: 'white' }}>
        {message}
      </p>
    </div>
  );
};
