import React, { useEffect, useMemo, createRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from './components/NavBar';
import { Footer } from './components/Footer';
import IndexRoute from './routes/index';
import setAuthToken from './helpers/utils';
import ReactGA from 'react-ga4';
import Hotjar from '@hotjar/browser';
import './index.css';
import { GlobalModal } from './components/Modal';
import { footerLinksConfig } from './data/footerLinks';
import { setFooterNavigation } from './ducks/Global/actions';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const siteId = 3690945;
const hotjarVersion = 6;
ReactGA.initialize('G-V5H4JJZZHV');
Hotjar.init(siteId, hotjarVersion);

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isVisible = useSelector((state) => state.global.isLegalModalOpen);
  const legalTopic = useSelector((state) => state.global.legalTopic);

  // Memoize the links array
  const links = useMemo(
    () => footerLinksConfig[location.pathname] || [],
    [location.pathname]
  );

  const sectionsRefs = useMemo(() => {
    const refs = {};
    links.forEach((link) => {
      refs[link.link] = createRef();
    });
    return refs;
  }, [links]);

  useEffect(() => {
    dispatch(setFooterNavigation(links));
  }, [dispatch, links]);

  const handleScroll = (ref) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    ReactGA.gtag('config', 'G-V5H4JJZZHV', {
      page_path: currentPath,
    });
  }, [location]);

  return (
    <div className='app'>
      <GlobalModal isVisible={isVisible} content={legalTopic} />
      {/* {location.pathname.includes('results') && <TopBanner />} */}
      <NavBar />
      <IndexRoute sectionsRefs={sectionsRefs} handleScroll={handleScroll} />
      <Footer sectionsRefs={sectionsRefs} />
    </div>
  );
};

export default App;
