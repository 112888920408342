import { put, takeLatest, call } from "redux-saga/effects";
import axios from 'axios';
import { SEND_EMAIL } from "./constants";
import {
  sendEmailSuccess,
  sendEmailError
} from "./actions";

export function* sendEmail({emailData}) {

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const body = JSON.stringify({emailData});

  try {
    const payload = yield call(axios.post, emailData.url, body, config);
    yield put(sendEmailSuccess(payload));
    console.log('Email sent successfully!', payload);
  } catch (error) {
    yield put(sendEmailError('Error sending email, please try again later'))
    console.error('Error sending email:', error);
  }
};

export default function* globalSaga() {
  yield takeLatest(SEND_EMAIL, sendEmail);
}