import React, { useState, useEffect } from 'react';
import { ProgramRecommendationPage } from '../presentational/ProgramRecommendation';
import axios from 'axios';

export const ProgramRecommendation = () => {
  const [hoveredEmoji, setHoveredEmoji] = useState(null);
  const [emojiItems, setEmojiItems] = useState([]);
  const [numberOfWorkouts, setNumberOfWorkouts] = useState(0);
  const numberOfIcons = 150;

  useEffect(() => {
    const emojis = ['💪', '🔥', '🚀', '💯', '⭐️']; // Add more emojis as needed
    const generatedEmojis = [];

    for (let i = 0; i < numberOfIcons; i++) {
      const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];
      const randomRotation = Math.random() * 30 - 15; // Random rotation between -15 and 15 degrees
      generatedEmojis.push(
        <span
          key={i}
          role='img'
          aria-label='Emoji'
          className={`emoji-item ${i === hoveredEmoji ? 'animate' : ''}`}
          onMouseEnter={() => setHoveredEmoji(i)}
          onMouseLeave={() => setHoveredEmoji(null)}
          style={{ transform: `rotate(${randomRotation}deg)` }}
        >
          {randomEmoji}
        </span>
      );
    }

    setEmojiItems(generatedEmojis);
  }, []);

  useEffect(() => {
    getWorkoutsCompleted();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getWorkoutsCompleted = async () => {
    try {
      const response = await axios.get(
        'https://api.getnubod.com/api/workout/library/count'
      );
      const workoutCount = response.data.count;
      setNumberOfWorkouts(workoutCount);
    } catch (error) {
      console.error('Error fetching workout count:', error);
    }
  };

  return (
    <div className='program-recommendation-page'>
      <div className='emoji-background'>
        {emojiItems} {/* Use the emojiItems state here */}
      </div>
      <ProgramRecommendationPage numberOfWorkouts={numberOfWorkouts} />
    </div>
  );
};
