import React from 'react';
import { Progress, Input, Tabs } from 'antd';
import { capitalize } from '../../helpers/utils';
import { faqData } from '../../data/faq';
import { renderFaq } from '../Faq';
import { LeadForm } from '../Form';
import './index.css';

export const MacronutrientCalculatorComponent = (props) => {
  const renderStat = (macro) => {
    return (
      <div>
        <p className='subtitle bold' style={{ fontSize: '24px', color: '' }}>
          {macro[0] === 'calories' ? macro[1] : `${macro[1]} g`}
        </p>
        <p style={{ fontSize: '14px', margin: 0 }}>{capitalize(macro[0])}</p>
      </div>
    );
  };

  const renderProgress = () => {
    // Filter the macros array to include only calories, protein, carbs, and fat
    let macros = Object.entries(props.results).filter(
      (r) =>
        r[0] === 'calories' ||
        r[0] === 'protein' ||
        r[0] === 'carbs' ||
        r[0] === 'fat'
    );
    // Find the total calories value
    const totalCalories = props.results.calories;

    const progressColors = {
      '0%': '#2e3192',
      '100%': '#2761f1',
    };
    return (
      <div
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
      >
        {macros.map((macro) => {
          let percentage;
          if (macro[0] === 'calories') {
            percentage = 100;
          } else if (macro[0] === 'protein' || macro[0] === 'carbs') {
            percentage = ((macro[1] * 4) / totalCalories) * 100;
          } else if (macro[0] === 'fat') {
            percentage = ((macro[1] * 9) / totalCalories) * 100;
          }

          return (
            <div key={macro[0]} style={{ padding: '10px' }}>
              <Progress
                type='circle'
                strokeColor={progressColors}
                status='active'
                percent={percentage}
                format={() => renderStat(macro)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderCustomMacrosInput = () => {
    if (props.macronutrientAnswers.isCustomMacros) {
      return (
        <div>
          <div className='three-col-input mb-20'>
            {Object.entries(props.macronutrientAnswers.customMacros).map(
              (macro) => (
                <Input
                  onChange={(e) =>
                    props.setCustomMacros(macro[0], Number(e.target.value))
                  }
                  value={props.macronutrientAnswers.customMacros[macro[0]]}
                  type='number'
                  addonAfter={`${capitalize(macro[0])} %`}
                  maxLength={2}
                />
              )
            )}
          </div>
          {props.percentageError ? (
            <p
              className='mb-20'
              style={{ textAlign: 'center', color: 'red', fontSize: '14px' }}
            >
              Macro percentages must equal 100%
            </p>
          ) : null}
        </div>
      );
    }
  };

  const items = [
    {
      key: 'balanced',
      label: `Balanced`,
      explanation:
        'Suitable for individuals seeking a well-rounded approach to nutrition, maintaining a balance between macronutrients (carbs, protein, fat) for overall health and well-being.',
    },
    {
      key: 'low-carb',
      label: `Low Carb`,
      explanation:
        'Ideal for people aiming to reduce their carbohydrate intake, which may be helpful for weight loss or managing conditions like diabetes.',
    },
    {
      key: 'high-carb',
      label: `High Carb`,
      explanation:
        'Beneficial for individuals engaged in intense physical activities or endurance training, as carbohydrates provide energy for performance.',
    },
    {
      key: 'high-protein',
      label: `High Protein`,
      explanation:
        'Recommended for those looking to support muscle building, recovery, and overall body composition improvements.',
    },
    {
      key: 'ketogenic',
      label: `Ketogenic`,
      explanation:
        'Suitable for individuals seeking to achieve ketosis, a metabolic state where the body burns fat for fuel, potentially aiding in weight loss and certain health conditions.',
    },
    {
      key: 'custom',
      label: `Custom`,
      explanation: 'For experienced individuals who want more personalization.',
    },
  ];

  const formInfo = {
    title: 'GET A CUSTOMIZED SERVING GUIDE BASED ON YOUR RESULTS',
    subtitle:
      'Access the full guide now, including your customized hand portion serving guide, and a bonus gift!',
    buttonText: 'UNLOCK YOUR FREE GUIDE',
    url: '/api/sendgrid/send-macro-email',
    templateId: 'd-b2486d4983194680877e78cff466177b',
    tags: ['Subscriber', 'Tools', '52 Recipes Ebook'],
    subject: 'Your Macronutrient Calculator Report',
    source: 'macronutrients',
  };

  const style = {
    width: '100%',
    margin: '0 auto',
  };

  return (
    <div style={{ width: '100%' }}>
      <div className='global-card mb-30' style={{ paddingTop: '34px' }}>
        <p className='step-text'>STEP 1</p>
        <h3 className='tools-section-title uppercase'>YOUR STATS AND GOAL</h3>
        <div className='three-col-input'>
          {props.renderDropdown(
            props.questionOptions.unit.options,
            'Unit',
            'unit'
          )}
          {props.renderDropdown(
            props.questionOptions.gender.options,
            'Gender',
            'gender'
          )}
          {props.renderInputField('Weight', 'weight')}
        </div>
        <div className='three-col-input'>
          {props.renderInputField('Age', 'age')}
          {props.renderDropdown(
            props.questionOptions.height.options,
            'Height',
            'height'
          )}
          <div>
            {props.renderDropdown(
              props.questionOptions.bmrFormula.options,
              'Formula',
              'bmrFormula'
            )}
            {props.macronutrientAnswers.bmrFormula === 'katch-mcardle'
              ? props.renderInputField(
                  'Body Fat Percentage',
                  'bodyFatPercentage'
                )
              : null}
          </div>
        </div>
        <div className='two-col-input'>
          {props.renderDropdown(
            props.questionOptions.activityFactor.options,
            'Activity Factor',
            'activityFactor'
          )}
          {props.renderDropdown(
            props.questionOptions.goal.options,
            'Goal',
            'goal'
          )}
        </div>
      </div>

      <div className='global-card mb-30' style={{ paddingTop: '34px' }}>
        <p className='step-text'>STEP 2</p>
        <h3 className='tools-section-title'>
          NOW, LET'S CHOOSE YOUR DIETARY PREFERENCE
        </h3>
        <div>
          <Tabs
            centered={true}
            defaultActiveKey='balanced'
            items={items}
            onChange={(value) => props.setMacroPreference(value)}
            animated={false}
          />
          <p
            className='subtitle-text'
            style={{
              textAlign: 'center',
              width: '100%',
              maxWidth: '600px',
              margin: '0 auto',
              marginBottom: '10px',
            }}
          >
            {
              items.find(
                (item) =>
                  item.key === props.macronutrientAnswers.macroPreference
              ).explanation
            }
          </p>
          {renderCustomMacrosInput()}
        </div>
        {renderProgress()}
      </div>
      <div className='mb-20'>
        <LeadForm
          goal={props.macronutrientAnswers.goal}
          gender={props.macronutrientAnswers.gender}
          results={props.results}
          macroPreference={props.macronutrientAnswers.macroPreference}
          percentageError={props.percentageError}
          formInfo={formInfo}
          step={true}
        />
      </div>

      <div className='global-card'>
        {renderFaq(faqData.macronutrientCalculator, style)}
      </div>
    </div>
  );
};
