import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'antd';
import { sendEmail } from '../../ducks/Global/actions';
import { SET_FORM_VALUES } from '../../ducks/Global/constants';
import { isValidEmail, addToMailChimp } from '../../helpers/utils';
import { CheckCircleOutlined } from '@ant-design/icons';
import '../MacroNutrientCalculatorComponent/index.css';

export const LeadForm = ({
  goal,
  gender,
  results,
  macroPreference,
  percentageError,
  formInfo,
  step,
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const form = useSelector((state) => state.global.form);
  const sendMacroEmailLoading = useSelector(
    (state) => state.global.sendEmailLoading
  );
  const sendMacroEmailError = useSelector(
    (state) => state.global.sendEmailError
  );
  const successMessage = useSelector((state) => state.global.successMessage);

  const handleNameChange = (e, field) => {
    const updatedForm = { ...form, [field]: e.target.value.toLowerCase() };
    dispatch({ type: SET_FORM_VALUES, payload: updatedForm });
  };

  const handleFormSubmit = () => {
    let emailData = {
      url: formInfo.url,
      form: form,
      templateId: formInfo.templateId,
      results: results || null,
      goal: goal || null,
      gender: gender || null,
      subject: formInfo.subject,
      source: formInfo.source,
    };
    if (!form) {
      setError('Please fill out all fields');
      // } else if (!form?.agreed) {
      //   setError('Please agree to receive promotions and updates from Alvaro Fitness')
    } else if (!form?.name) {
      setError('Please enter your name');
    } else if (!form?.email) {
      setError('Please enter your email');
    } else if (form?.email && !isValidEmail(form?.email)) {
      setError('Please enter a valid email');
    } else {
      addToMailChimp(form, formInfo.tags);
      dispatch(sendEmail(emailData));
    }
  };

  return (
    <div
      className='global-card'
      style={{
        margin: '0 auto',
        textAlign: 'center',
        borderRadius: '0px',
        borderRadius: '10px',
      }}
    >
      {successMessage ? (
        <div>
          <CheckCircleOutlined
            style={{ fontSize: '50px', color: '#4EF704', marginBottom: '10px' }}
          />
          <h4 style={{ textAlign: 'center' }}>{successMessage}</h4>
        </div>
      ) : (
        <>
          {step && <p className='step-text'>STEP 3</p>}
          <div
            style={{
              width: '100%',
              maxWidth: '500px',
              margin: '0 auto',
              textAlign: 'center',
              borderRadius: '8px',
            }}
          >
            <h3 className='tools-section-title'>{formInfo.title}</h3>
            <p className='mb-30'>{formInfo.subtitle}</p>
            <div>
              <Input
                style={{ height: '50px', marginBottom: '10px' }}
                placeholder='Name'
                onChange={(e) => handleNameChange(e, 'name')}
                width='100%'
              />
              <Input
                style={{ height: '50px', marginBottom: '10px' }}
                placeholder='Email'
                onChange={(e) => handleNameChange(e, 'email')}
                width='100%'
              />
            </div>

            <div>
              {/* Add Checkbox if needed */}
              {error && (
                <p
                  style={{
                    color: 'red',
                    marginBottom: '10px',
                    fontSize: '14px',
                  }}
                >
                  {error}
                </p>
              )}
              {sendMacroEmailError && (
                <p
                  style={{
                    color: 'red',
                    marginBottom: '10px',
                    fontSize: '14px',
                  }}
                >
                  {sendMacroEmailError}
                </p>
              )}
            </div>

            <Button
              style={{ height: '50px', width: '100%' }}
              className='mb-10'
              type='primary'
              size='large'
              onClick={() => handleFormSubmit()}
              loading={sendMacroEmailLoading}
              disabled={percentageError && macroPreference === 'custom'}
            >
              {formInfo.buttonText}
            </Button>

            <p style={{ fontSize: '12px', color: '#555' }}>
              100% privacy and unsubscribe anytime! We'll never rent or sell
              your information or spam you.
            </p>
          </div>
        </>
      )}
    </div>
  );
};
