export const recommendations = [
  {
    goal: 'lose',
    gender: 'female',
    equipment: 'gym',
    experience: 'beginner',
    help: 'all',
    program:
      'Shed Fat. Get Toned.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for females looking to shed unwanted pounds, this program is here to guide you every step of the way. Designed for beginners, this comprehensive plan combines effective gym workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'lose',
    gender: 'female',
    equipment: 'gym',
    experience: 'intermediate',
    help: 'all',
    program:
      'Shed Fat. Get Toned.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for females looking to shed unwanted pounds, this program is here to guide you every step of the way. Designed for those transitioning from beginner to intermediate levels, it combines effective gym workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'lose',
    gender: 'female',
    equipment: 'dumbbells',
    experience: 'beginner',
    help: 'all',
    program:
      'Shed Fat. Get Toned.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for females looking to shed unwanted pounds, this program is here to guide you every step of the way. Designed for beginners, this comprehensive plan combines effective dumbbell workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'lose',
    gender: 'female',
    equipment: 'dumbbells',
    experience: 'intermediate',
    help: 'all',
    program:
      'Shed Fat. Get Toned.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for females looking to shed unwanted pounds, this program is here to guide you every step of the way. Designed for those transitioning from beginner to intermediate levels, it combines effective dumbbell workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'gain',
    gender: 'female',
    equipment: 'gym',
    experience: 'beginner',
    help: 'all',
    program:
      'Build Muscle. Shed Fat.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for females looking to build muscle, this program is here to guide you every step of the way. Designed for beginners, this comprehensive plan combines effective gym workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'gain',
    gender: 'female',
    equipment: 'gym',
    experience: 'intermediate',
    help: 'all',
    program:
      'Build Muscle. Shed Fat.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for females looking to build muscle, this program is here to guide you every step of the way. Designed for those transitioning from beginner to intermediate levels, it combines effective gym workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'gain',
    gender: 'female',
    equipment: 'dumbbells',
    experience: 'beginner',
    help: 'all',
    program:
      'Build Muscle. Shed Fat.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for females looking to build muscle, this program is here to guide you every step of the way. Designed for beginners, this comprehensive plan combines effective dumbbell workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'gain',
    gender: 'female',
    equipment: 'dumbbells',
    experience: 'intermediate',
    help: 'all',
    program:
      'Build Muscle. Shed Fat.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for females looking to build muscle, this program is here to guide you every step of the way. Designed for those transitioning from beginner to intermediate levels, it combines effective dumbbell workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'lose',
    gender: 'male',
    equipment: 'gym',
    experience: 'beginner',
    help: 'all',
    program:
      'Shed Fat. Build Muscle.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for males looking to shed unwanted pounds, this program is here to guide you every step of the way. Designed for beginners, this comprehensive plan combines effective gym workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'lose',
    gender: 'male',
    equipment: 'gym',
    experience: 'intermediate',
    help: 'all',
    program:
      'Shed Fat. Build Muscle.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for males looking to shed unwanted pounds, this program is here to guide you every step of the way. Designed for those transitioning from beginner to intermediate levels, it combines effective gym workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'lose',
    gender: 'male',
    equipment: 'dumbbells',
    experience: 'beginner',
    help: 'all',
    program:
      'Shed Fat. Build Muscle.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for males looking to shed unwanted pounds, this program is here to guide you every step of the way. Designed for beginners, this comprehensive plan combines effective dumbbell workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'lose',
    gender: 'male',
    equipment: 'dumbbells',
    experience: 'intermediate',
    help: 'all',
    program:
      'Shed Fat. Build Muscle.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for males looking to shed unwanted pounds, this program is here to guide you every step of the way. Designed for those transitioning from beginner to intermediate levels, it combines effective dumbbell workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'gain',
    gender: 'male',
    equipment: 'gym',
    experience: 'beginner',
    help: 'all',
    program:
      'Build Muscle. Shed Fat.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for males looking to build muscle, this program is here to guide you every step of the way. Designed for beginners, this comprehensive plan combines effective gym workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'gain',
    gender: 'male',
    equipment: 'gym',
    experience: 'intermediate',
    help: 'all',
    program:
      'Build Muscle. Shed Fat.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for males looking to build muscle, this program is here to guide you every step of the way. Designed for those transitioning from beginner to intermediate levels, it combines effective gym workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'gain',
    gender: 'male',
    equipment: 'dumbbells',
    experience: 'beginner',
    help: 'all',
    program:
      'Build Muscle. Shed Fat.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for males looking to build muscle, this program is here to guide you every step of the way. Designed for beginners, this comprehensive plan combines effective dumbbell workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
  {
    goal: 'gain',
    gender: 'male',
    equipment: 'dumbbells',
    experience: 'intermediate',
    help: 'all',
    program:
      'Build Muscle. Shed Fat.\nTransform Your Body.\nEven on a Busy Schedule.',
    description:
      'Ready to take your fitness to the next level? Tailored specifically for males looking to build muscle, this program is here to guide you every step of the way. Designed for those transitioning from beginner to intermediate levels, it combines effective dumbbell workouts with a customized and detailed nutrition plan to ensure you achieve outstanding results while enjoying the process.',
  },
];
