import { put, takeLatest, call } from "redux-saga/effects";
import axios from 'axios';
import { REGISTER, LOAD_USER, LOGIN_USER } from "./constants";
import {
  registerSuccess,
  registerError,
  loadUserSuccess,
  loadUserError,
  loginUserSuccess,
  loginUserError
} from "./actions";
import setAuthToken from '../../helpers/utils';

export function* loadUser() {
  if(localStorage.token){
    setAuthToken(localStorage.token);
  }

  try {
    const payload = yield call(axios.get, 'api/auth');
    yield put(loadUserSuccess(payload.data));

  } catch (err) {
    console.log(err);
    yield put(loadUserError(err));
  }
}

export function* register({ name, email, password }) {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const body = JSON.stringify({
    name,
    email,
    password
  });
  try {
    const payload = yield call(axios.post, '/api/users', body, config)
    yield put(registerSuccess(payload.data));
    yield call(loadUser);

  } catch (err) {
    const errors = err.response.data.errors
    console.log(errors);
    console.log(err);
    yield put(registerError(errors));
  }
}

export function* loginUser({ email, password }) {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const body = JSON.stringify({
    email,
    password
  });
  try {
    const payload = yield call(axios.post, '/api/auth', body, config)
    yield put(loginUserSuccess(payload.data));
    yield call(loadUser);

  } catch (err) {
    const errors = err.response.data.errors
    console.log(errors);
    console.log(err);
    yield put(loginUserError(errors));
  }
}

export default function* authSaga() {
  yield takeLatest(REGISTER, register);
  yield takeLatest(LOAD_USER, loadUser);
  yield takeLatest(LOGIN_USER, loginUser);
}
