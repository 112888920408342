import { put, takeLatest, call } from "redux-saga/effects";
import axios from 'axios';
import { GET_ORDERS, CREATE_ORDER, DELETE_ORDER } from "./constants";
import {
  getOrdersSuccess,
  getOrdersError,
  createOrderSuccess,
  createOrderError,
  deleteOrderSuccess
} from "./actions";

export function* getOrders(){
  try {
    const payload = yield call(axios.get, 'api/order');
    yield put(getOrdersSuccess(payload.data));

  } catch (err) {
    console.log(err);
    yield put(getOrdersError(err));
  }
}

export function* createOrder({customer, meals, orderQty, orderTotal, pickUpDate}) {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  let orderedMeals = meals.filter(meal => meal.qty > 0); //removes meals that weren't ordered

  const body = JSON.stringify({
    customer,
    orderedMeals,
    orderQty,
    orderTotal,
    pickUpDate
  });
  
  try {
    const payload = yield call(axios.post, '/api/order/', body, config);
    yield put(createOrderSuccess(payload.data));
  
  } catch (err) {
    console.log(err);
    yield put(createOrderError(err));
  }
}

export function* deleteOrder({id}){
  try {
    const token = window.localStorage.token;
    const config = {
      method: 'DELETE',
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      },
    };
    yield call(axios.delete, `/api/order/${id}`, config);
    yield put(deleteOrderSuccess(id));

  } catch (err) {
    const error = yield err.json();
    console.log(error);
  }
}

export default function* ordersSaga() {
  yield takeLatest(GET_ORDERS, getOrders);
  yield takeLatest(CREATE_ORDER, createOrder);
  yield takeLatest(DELETE_ORDER, deleteOrder);
}