import React from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export const renderFaq = (faqArr, style, isProductLanding, collapseStyle) => {
  return (
    <div style={style}>
      <h3
        className={
          !isProductLanding
            ? 'tools-section-title'
            : 'landing-page title center-aligned mb-50'
        }
      >
        Frequently Asked{' '}
        <span className='text-gradient underlined-title'>Questions</span>
      </h3>
      <Collapse bordered={false} style={collapseStyle}>
        {faqArr.map((item) => (
          <Panel header={item.header} key={item.key}>
            {item.text}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};
