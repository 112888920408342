import { put, takeLatest, call } from "redux-saga/effects";
import axios from 'axios';
import { GET_INGREDIENTS } from "./constants";
import {
  getIngredientsSuccess,
  getIngredientsError
} from "./actions";

export function* getIngredients() {
  try {
    const payload = yield call(axios.get, 'api/ingredient');
    yield put(getIngredientsSuccess(payload.data));

  } catch (err) {
    console.log(err);
    yield put(getIngredientsError(err));
  }
}

export default function* ingredientsSaga() {
  yield takeLatest(GET_INGREDIENTS, getIngredients);
}