import React, { useState, useEffect } from 'react';
import { Input, Select, Tabs, Table } from 'antd';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { faqData } from '../../data/faq';
import { renderFaq } from '../Faq';
import { LeadForm } from '../../components/Form';

import './index.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const { Option } = Select;

export const OneRepMaxCalculator = () => {
  const [form, setForm] = useState({
    weight: 120,
    reps: 8,
    unit: 'lb',
    oneRepMax: 0,
  });
  const [percentageChartWeights, setPercentageChartWeights] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [view, setView] = useState('table');

  useEffect(() => {
    const initialOneRepMax = calculateOneRepMax(form.weight, form.reps);
    setForm({ ...form, oneRepMax: initialOneRepMax });
  }, []);

  useEffect(() => {
    const { chartData, tableData } = calculateChartDataAndTableData(
      form.oneRepMax
    );
    setPercentageChartWeights(chartData);
    setTableData(tableData);
  }, [form.oneRepMax]);

  const calculateOneRepMax = (weight, reps) => {
    const oneRepMax = weight / (1.0278 - 0.0278 * reps);
    return Number(oneRepMax.toFixed(1));
  };

  const calculateEstimatedRepsForPercentage = (oneRepMax, weight) => {
    if (weight === oneRepMax) {
      return 1;
    } else {
      const estimatedReps = Math.round((oneRepMax / weight - 1) / 0.0333);
      return estimatedReps;
    }
  };

  const calculateChartDataAndTableData = (oneRepMax) => {
    const percentages = [100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45];
    const chartData = [];
    const tableData = [];

    percentages.forEach((percentage) => {
      const weight = Math.ceil(oneRepMax * (percentage / 100) * 100) / 100;
      const estimatedReps = calculateEstimatedRepsForPercentage(
        oneRepMax,
        weight
      );

      chartData.push({
        weight: weight.toFixed(1),
        estimatedReps: estimatedReps.toFixed(0),
      });

      tableData.push({
        percentage: `${percentage}%`,
        weight: weight.toFixed(1),
        estimated_reps: estimatedReps.toFixed(0),
      });
    });

    return { chartData, tableData };
  };

  const convertWeight = (weight, unit) => {
    if (unit === 'kg') {
      return weight * 0.45359237;
    } else if (unit === 'lb') {
      return weight * 2.20462262185;
    }
  };

  const handleInputChange = (name, value) => {
    const updatedForm = { ...form, [name]: value };
    setForm(updatedForm);

    if (name === 'weight' || name === 'reps') {
      const oneRepMax = calculateOneRepMax(
        updatedForm.weight,
        updatedForm.reps
      );
      updatedForm.oneRepMax = oneRepMax;
      const { chartData, tableData } =
        calculateChartDataAndTableData(oneRepMax);
      setPercentageChartWeights(chartData);
      setTableData(tableData);
    }
  };

  const handleUnitChange = (value) => {
    const convertedWeight = convertWeight(form.weight, value);
    const updatedForm = {
      ...form,
      weight: convertedWeight,
      unit: value,
    };

    const oneRepMax = calculateOneRepMax(updatedForm.weight, form.reps);
    updatedForm.oneRepMax = oneRepMax;
    setPercentageChartWeights(calculateChartDataAndTableData(oneRepMax));

    setForm(updatedForm);
  };

  const renderInputField = (label, name) => {
    return (
      <div className='input-container'>
        <p className='label'>{label}</p>
        <Input
          onChange={(e) => handleInputChange(name, e.target.value)}
          className='input custom-input'
          type='number'
          addonAfter={
            name === 'weight' && (
              <Select value={form.unit} onChange={handleUnitChange}>
                <Option value='lb'>lb</Option>
                <Option value='kg'>kg</Option>
              </Select>
            )
          }
          value={form[name]}
        />
      </div>
    );
  };

  const renderPercentageChart = () => {
    const data = {
      labels: [
        '100%',
        '95%',
        '90%',
        '85%',
        '80%',
        '75%',
        '70%',
        '65%',
        '60%',
        '55%',
        '50%',
        '45%',
      ],
      datasets: [
        {
          label: 'Weight',
          data: percentageChartWeights.map((item) => item.weight),
          borderColor: '#2761f1',
          backgroundColor: 'rgba(39, 97, 241, 0.2)',
          pointRadius: 6,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value) => `${value + form.unit}`,
          },
        },
      },
      animation: {
        easing: 'easeInOutBack',
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              const weightIndex = context.dataIndex;
              const weight = percentageChartWeights[weightIndex].weight;
              const estimatedReps =
                percentageChartWeights[weightIndex].estimatedReps;
              const percentage = context.label;
              return [
                `${percentage}`,
                `Weight: ${weight} ${form.unit}`,
                `Estimated Reps: ${estimatedReps} reps`,
              ];
            },
          },
          backgroundColor: '##d3d3d3',
          titleFontSize: 12,
          bodyFontSize: 12,
          caretPadding: 10,
        },
      },
    };

    return (
      <div style={{ height: '325px' }}>
        <Line width={'100%'} height={320} data={data} options={options} />
      </div>
    );
  };

  const items = [
    {
      key: 'table',
      label: `Table View`,
    },
    {
      key: 'chart',
      label: `Chart View`,
    },
  ];

  const renderTable = () => {
    const columns = [
      {
        title: '% of 1RM',
        dataIndex: 'percentage',
        key: 'percentage',
        align: 'center',
        width: '33.33%',
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
        key: 'weight',
        align: 'center',
        width: '33.33%',
      },
      {
        title: 'Estimated Reps',
        dataIndex: 'estimated_reps',
        key: 'estimated_reps',
        align: 'center',
        width: '33.33%',
      },
    ];

    return (
      <Table columns={columns} dataSource={tableData} pagination={false} />
    );
  };

  const formInfo = {
    title: 'Unlock Your Plug And Play Workout Template!',
    subtitle: 'A 5x per week program to build muscle and strength!',
    buttonText: 'Get My Free Program',
    url: '/api/sendgrid/send-macro-email',
    templateId: 'd-f437518302d94f4199a6e916b821c7a0',
    tags: ['Subscriber', 'Tools', 'Workout Template'],
    subject: "Here's Your Workout Template!",
    source: 'oneRepMax',
  };

  const renderGuide = () => {
    return (
      <div className='global-card mb-30'>
        <h3>Guide: Planning Workout Programs Based on One Rep Max</h3>
        <p className='mb-20'>
          Designing a workout program around your One Rep Max (1RM) can be an
          effective way to structure your strength training routine and achieve
          your fitness goals. By understanding your 1RM, you can tailor your
          workouts to optimize gains in strength and muscle mass. Here's a
          step-by-step guide to help you plan workout programs based on your
          1RM:
        </p>
        <div className='mb-20'>
          <h4> Step 1: Calculate Your One Rep Max</h4>
          <p>
            Before you begin planning your workout program, you need to
            determine your 1RM for the key exercises you'll be focusing on. Use
            a reliable formula or an online calculator to estimate your 1RM for
            exercises like squats, bench presses, deadlifts, and other compound
            movements.
          </p>
        </div>
        <div className='mb-20'>
          <h4>Step 3: Choose the Right Exercises</h4>
          <p>
            Step 3: Choose the Right Exercises Select exercises that align with
            your goals and engage multiple muscle groups. Compound exercises
            like squats, deadlifts, bench presses, and overhead presses are
            excellent choices. Include accessory exercises to target specific
            muscle groups.
          </p>
        </div>
        <div className='mb-20'>
          <h4>Step 4: Determine Training Zones</h4>
          <p>
            Based on your goals, divide your training zones according to
            intensity ranges. For strength, work in the 85-100% 1RM range; for
            hypertrophy, target 70-85% 1RM. Different zones will emphasize
            different adaptations, so structure your program accordingly.
          </p>
        </div>
        <div className='mb-20'>
          <h4>Step 5: Plan Periodization</h4>
          <p>
            Periodization involves organizing your program into distinct phases
            to prevent plateaus and overtraining. Consider a linear
            periodization where you gradually increase weights and decrease reps
            over several weeks. Alternatively, opt for undulating periodization,
            varying intensity and volume within the same week.
          </p>
        </div>
        <div className='mb-20'>
          <h4>Step 6: Structure Reps and Sets</h4>
          <p>
            Determine the number of sets and repetitions for each exercise based
            on your chosen training zone. For strength, opt for fewer reps (1-5)
            and more sets (3-6). For hypertrophy, aim for higher reps (6-12) and
            moderate sets (3-4).
          </p>
        </div>
        <div className='mb-20'>
          <h4> Step 7: Incorporate Progressive Overload</h4>
          <p>
            Progressive overload is key to strength gains. Gradually increase
            the weights you lift over time. Start with a weight you can
            comfortably lift for the prescribed reps and sets, and progressively
            increase it as your strength improves.
          </p>
        </div>
        <div className='mb-20'>
          <h4>Step 8: Include Rest and Recovery</h4>
          <p>
            Don't overlook the importance of rest and recovery. Allow muscles to
            recuperate by incorporating rest days and active recovery
            activities. Adequate sleep and proper nutrition also play a vital
            role in optimizing gains.
          </p>
        </div>
        <div className='mb-20'>
          <h4>Step 9: Monitor and Adjust</h4>
          <p>
            Regularly track your progress by recording the weights, reps, and
            sets you perform in each workout. Use a training journal or fitness
            app to keep records. As you progress, adjust the weights, reps, and
            sets to continue challenging your muscles.
          </p>
        </div>
        <div className='mb-20'>
          <h4> Step 10: Consult with a Professional</h4>
          <p>
            If you're new to strength training or unsure about program design,
            consider seeking guidance from a certified personal trainer or
            strength coach. They can help create a personalized program that
            aligns with your goals and ensures proper form to prevent injuries.
          </p>
        </div>
        <p>
          Remember that consistency and patience are crucial. Results take time,
          so stick to your program and make adjustments as needed. By planning
          your workout programs based on your 1RM, you'll have a structured
          approach that leads to meaningful progress and a stronger, healthier
          you.
        </p>
      </div>
    );
  };

  const style = {
    width: '100%',
    margin: '0 auto',
  };

  return (
    <div style={{ width: '100%' }}>
      <div className='mb-20'>
        <div className='global-card'>
          <div className='two-col-input'>
            {renderInputField('Weight', 'weight')}
            {renderInputField('Reps', 'reps')}
          </div>
          <div className='global-card bordered'>
            <h2 style={{ marginBottom: '0', textAlign: 'center' }}>
              Your one rep max is{' '}
              <span style={{ color: '#FFA726' }}>
                {form.oneRepMax} {form.unit}
              </span>
            </h2>
          </div>
        </div>
      </div>

      <div className='section'>
        <h3 className='tools-section-title'>
          Let's Take A Look At Your Percentage Chart
        </h3>
        <p
          className='subtitle-text'
          style={{
            width: '100%',
            maxWidth: '600px',
            textAlign: 'center',
            margin: '0 auto',
          }}
        >
          This table provides estimated repetitions for different percentages of
          your one-repetition maximum (1RM), helping you tailor your workout
          intensity.
        </p>
        <Tabs
          centered={true}
          defaultActiveKey='table'
          items={items}
          onChange={(value) => setView(value)}
          animated={false}
        />
        {view === 'table' ? renderTable() : renderPercentageChart()}
      </div>
      <div className='mb-20'>
        <LeadForm formInfo={formInfo} />
      </div>

      {renderGuide()}
      <div className='global-card'>
        {renderFaq(faqData.oneRepMaxCalculator, style)}
      </div>
    </div>
  );
};
