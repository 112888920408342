import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import AlvaroFitnessWhite from '../../assets/images/af-logo-white.png';
import AlvaroFitnessBlack from '../../assets/images/af-logo-black.png';
import AlvaroFitnessGradient from '../../assets/images/af-logo.png';
import './index.css';
import { connect } from 'react-redux';
import { logout } from '../../ducks/Auth/actions';
import { Button } from 'antd';

const NavBar = ({ auth, logout }) => {
  const location = useLocation();
  const [isRecommendationPage, setIsRecommendationPage] = useState(false);

  useEffect(() => {
    const checkRecommendationPage = () => {
      const isRecommendation =
        location.pathname === '/program-recommendation' ||
        location.pathname === '/program-recommendation/results' ||
        location.pathname === '/' ||
        location.pathname === '/tools/macronutrient-calculator';
      setIsRecommendationPage(isRecommendation);
    };

    checkRecommendationPage();
  }, [location]);

  const renderNavLinks = () => {
    let navLinks = [
      { key: 1, name: 'Menu', path: '/food-ordering' },
      { key: 2, name: 'Orders', path: '/orders', private: true },
      { key: 3, name: 'Orders Detail', path: '/orders-detail', private: true },
      { key: 4, name: 'Manage Meals', path: '/manage-meals', private: true },
    ];
    if (auth.token && location.pathname === '/food-ordering') {
      return navLinks.map((link) => {
        return (
          <div key={link.key} className='nav-link-container mt-10'>
            <NavLink className='nav-link mb-10' key={link.key} to={link.path}>
              <p>{link.name}</p>
            </NavLink>
          </div>
        );
      });
    }
  };

  return (
    <div
      className='nav-bar'
      style={{
        backgroundColor: isRecommendationPage ? 'white' : 'white',
      }}
    >
      <div
        className='nav-bar-content'
        style={{ justifyContent: isRecommendationPage && 'center' }}
      >
        <img
          className='logo'
          src={isRecommendationPage ? AlvaroFitnessBlack : AlvaroFitnessBlack}
          alt='logo'
        />
        <div style={{ display: 'flex', alignItems: 'spaceAround' }}>
          {renderNavLinks()}
        </div>
        <div className='nav-link-container mt-10'>
          {auth.isAuthenticated && (
            <Button type='secondary' onClick={logout}>
              Logout
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
