import React, { useState, useEffect } from 'react';
import { shortenText, starRating } from '../../helpers/utils';
import { BsFillChatQuoteFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { openReviewModal } from '../../ducks/Global/actions';
import { capitalize } from '../../helpers/utils';
import { reviews } from '../../data/reviews';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './index.css';

export const GoogleReviews = ({ color }) => {
  const dispatch = useDispatch();
  const [clientReviews, setReviews] = useState([]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    setReviews(reviews);
  }, []);

  return (
    <>
      <Carousel
        draggable={true}
        responsive={responsive}
        containerClass='carousel-container'
        infinite={true}
        itemClass='carousel-item-padding-40-px'
      >
        {clientReviews &&
          clientReviews.length > 0 &&
          clientReviews.map((review) => (
            <div className='review-card' key={review.author_name}>
              <BsFillChatQuoteFill style={{ fontSize: '50px', color: color }} />
              <div>
                <p className='mb-2'>{starRating(review.rating)}</p>
                <p className='subtitle mb-2'>{shortenText(review.text, 150)}</p>
                <p
                  className='link'
                  onClick={() => dispatch(openReviewModal(true, review))}
                >
                  Read More
                </p>
              </div>
              <p className='title' style={{ fontSize: '18px' }}>
                {capitalize(review.author_name)}
              </p>
            </div>
          ))}
      </Carousel>
    </>
  );
};
