import React from 'react';
import ConfirmationPage from '../presentational/ConfirmationPage';
import axios from 'axios';
import withNavigateHook from '../withNavigateHook';

class Confirmation extends React.Component {
  state={createdMeal: null}

  componentDidMount(){
    let createdMealId = window.location.pathname.split('/')[2];
    axios.get(`/api/order/${createdMealId}`).then(response => {
      this.setState({ createdMeal: response.data });
    })
    .catch(function(error) {
      console.log(error);
    });;
  }

  render(){
    return (
      this.state.createdMeal &&
      <div className='orders-page'>
        <ConfirmationPage {...this.props} createdMeal={this.state.createdMeal} /> 
      </div>
    )
  }
}

export default withNavigateHook(Confirmation);