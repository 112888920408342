import { useNavigate, useLocation } from 'react-router-dom'; 

const withRouter = WrappedComponent => props => {
 const navigate = useNavigate();
 const location = useLocation();

   // Mimic the 'history' object's 'push' and 'replace' methods
   const history = {
    push: (path, state) => navigate(path, { state }),
    replace: (path, state) => navigate(path, { replace: true, state }),
    location
  };

  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      location={location}
      history={history}
    />
  );
};

export default withRouter;