import React from 'react';
import { connect } from 'react-redux';
import withRouter from '../helpers/withRouter';
import { Input, Popover, Select, Button } from 'antd';
import MacronutrientIcon from '../assets/images/fruit.svg';
import Alvaro from '../assets/images/Alvaro1.png';
import OneRepMaxIcon from '../assets/images/weightlifting.svg';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { feetToCentimeters } from '../helpers/utils';
import { CLEAR_SUCCESS_MESSAGE } from '../ducks/Global/constants';
import { MacronutrientCalculatorComponent } from '../components/MacroNutrientCalculatorComponent';
import '../index.css';

const { Option } = Select;

class MacronutrientCalculator extends React.Component {
  constructor(props) {
    super();
    this.myRef = React.createRef();
    this.state = {
      // unit: 'imperial',
      // customActivityFactor: '',
      percentageError: false,
      results: {
        bmr: 0,
        maintenanceCalories: 0,
        calories: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
      },
      macronutrientAnswers: {
        unit: 'imperial',
        gender: 'male',
        weight: 180,
        age: 35,
        height: "5'10",
        bodyFatPercentage: 15,
        activityFactor: 1.55,
        bmrFormula: 'mifflin-st-jeor',
        goal: 'moderate-weight-loss',
        macroPreference: 'balanced',
        isCustomMacros: false,
        customMacros: { protein: 35, carbs: 35, fat: 30 },
      },
      componentViewType: [
        { key: 1, view: 'cards', active: true },
        { key: 2, view: 'list', active: false },
      ],
      questionOptions: {
        gender: {
          name: 'Biological gender',
          options: [
            { name: 'Male', value: 'male' },
            { name: 'Female', value: 'female' },
          ],
        },
        unit: {
          name: 'Unit',
          options: [
            { name: 'Imperial', value: 'imperial' },
            // {name: 'Metric', value: 'metric'}
          ],
        },
        height: {
          name: 'Height',
          options: [
            { name: "3'0", value: "3'0" },
            { name: "3'1", value: "3'1" },
            { name: "3'2", value: "3'2" },
            { name: "3'3", value: "3'3" },
            { name: "3'4", value: "3'4" },
            { name: "3'5", value: "3'5" },
            { name: "3'6", value: "3'6" },
            { name: "3'7", value: "3'7" },
            { name: "3'8", value: "3'8" },
            { name: "3'9", value: "3'9" },
            { name: "3'10", value: "3'10" },
            { name: "3'11", value: "3'11" },
            { name: "4'0", value: "4'0" },
            { name: "4'1", value: "4'1" },
            { name: "4'2", value: "4'2" },
            { name: "4'3", value: "4'3" },
            { name: "4'4", value: "4'4" },
            { name: "4'5", value: "4'5" },
            { name: "4'6", value: "4'6" },
            { name: "4'7", value: "4'7" },
            { name: "4'8", value: "4'8" },
            { name: "4'9", value: "4'9" },
            { name: "4'10", value: "4'10" },
            { name: "4'11", value: "4'11" },
            { name: "5'0", value: "5'0" },
            { name: "5'1", value: "5'1" },
            { name: "5'2", value: "5'2" },
            { name: "5'3", value: "5'3" },
            { name: "5'4", value: "5'4" },
            { name: "5'5", value: "5'5" },
            { name: "5'6", value: "5'6" },
            { name: "5'7", value: "5'7" },
            { name: "5'8", value: "5'8" },
            { name: "5'9", value: "5'9" },
            { name: "5'10", value: "5'10" },
            { name: "5'11", value: "5'11" },
            { name: "6'0", value: "6'0" },
            { name: "6'1", value: "6'1" },
            { name: "6'2", value: "6'2" },
            { name: "6'3", value: "6'3" },
            { name: "6'4", value: "6'4" },
            { name: "6'5", value: "6'5" },
            { name: "6'6", value: "6'6" },
            { name: "6'7", value: "6'7" },
            { name: "6'8", value: "6'8" },
            { name: "6'9", value: "6'9" },
            { name: "6'10", value: "6'10" },
            { name: "6'11", value: "6'11" },
            { name: "7'0", value: "7'0" },
            { name: "7'1", value: "7'1" },
            { name: "7'2", value: "7'2" },
            { name: "7'3", value: "7'3" },
            { name: "7'4", value: "7'4" },
            { name: "7'5", value: "7'5" },
            { name: "7'6", value: "7'6" },
            { name: "7'7", value: "7'7" },
            { name: "7'8", value: "7'8" },
            { name: "7'9", value: "7'9" },
            { name: "7'10", value: "7'10" },
            { name: "7'11", value: "7'11" },
            { name: "8'0", value: "8'0" },
            { name: "8'1", value: "8'1" },
            { name: "8'2", value: "8'2" },
            { name: "8'3", value: "8'3" },
            { name: "8'4", value: "8'4" },
            { name: "8'5", value: "8'5" },
            { name: "8'6", value: "8'6" },
            { name: "8'7", value: "8'7" },
            { name: "8'8", value: "8'8" },
            { name: "8'9", value: "8'9" },
            { name: "8'10", value: "8'10" },
            { name: "8'11", value: "8'11" },
            { name: "9'0", value: "9'0" },
          ],
        },
        activityFactor: {
          name: 'Activity',
          options: [
            { name: 'None - Little to no exercise', value: 1.2 },
            {
              name: 'A little - 1 to 3 hours of sports or exercise per week',
              value: 1.375,
            },
            {
              name: 'Some - 4 to 6 hours of sports or exercise per week',
              value: 1.55,
            },
            {
              name: 'A lot - 7 to 9 hours of sports or exercise per week',
              value: 1.725,
            },
            {
              name: 'Extremely Active - 10+ hours of sports or exercise per week',
              value: 1.9,
            },
            // {name: 'Custom', value: ''}
          ],
        },
        bmrFormula: {
          name: 'BMR Formula',
          options: [
            {
              name: 'Mifflin-St Jeor (Recommended)',
              value: 'mifflin-st-jeor',
              explanation:
                "This formula is the most accurate if you don't know your body fat percentage.",
            },
            {
              name: 'Katch-McArdle',
              value: 'katch-mcardle',
              explanation:
                'This formula is the most accurate if you know your body fat percentage and your body fat is less than 20%. If you are not sure about your body fat, use the Mifflin-St Jeor formula.',
            },
            {
              name: 'Harris-Benedict',
              value: 'harris-benedict',
              explanation:
                'This was one of the first equations used to predict BMR, however, Mifflin-St Jeor is now considered more accurate.',
            },
          ],
        },
        goal: {
          name: 'Goal',
          options: [
            {
              name: 'Rapid Weight Loss ~2lb per week',
              value: 'rapid-weight-loss',
              explanation:
                'This goal is suitable for individuals with significant weight to lose, but may not be ideal for those who are slightly overweight or seeking to achieve a leaner physique.',
            },
            {
              name: 'Moderate Weight Loss ~1lb per week',
              value: 'moderate-weight-loss',
              explanation:
                'This goal is generally suitable for most individuals. It allows for consistent weight loss without significant muscle loss, cravings, or fatigue.',
            },
            {
              name: 'Slow Weight Loss ~0.5lb per week',
              value: 'slow-weight-loss',
              explanation:
                'This goal is well-suited for individuals who are already lean and looking to achieve even greater leanness or for athletes following intense training regimens, such as endurance athletes and high-performance athletes in various sports.',
            },
            {
              name: 'Maintain Weight',
              value: 'maintain-weight',
              explanation:
                "This is a favorable goal if you're content with your current body composition or if you desire to take a break from dieting.",
            },
            {
              name: 'Slow Weight Gain ~0.5lb per week',
              value: 'slow-weight-gain',
              explanation:
                'This is an ideal goal for advanced weightlifters aiming to optimize muscle gain and performance while minimizing body fat gain.',
            },
            {
              name: 'Moderate Weight Gain ~1lb per week',
              value: 'moderate-weight-gain',
              explanation:
                'This is an excellent goal for intermediate weightlifters who seek to maximize muscle gain and performance while minimizing body fat gain.',
            },
            {
              name: 'Rapid Weight Gain ~2lb per week',
              value: 'rapid-weight-gain',
              explanation:
                'This is an ideal goal for beginner weightlifters aiming to optimize muscle gain and performance while minimizing body fat gain.',
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    this.calculateBMR(this.state.macronutrientAnswers, (bmr) => {
      // This function will be executed after BMR is calculated and state is updated.
      this.setState({
        results: { ...this.state.results, bmr: Math.round(bmr) },
      });
    });
    window.addEventListener('message', this.handleMessage);

    if (this.props.location.pathname === '/tools/macronutrient-calculator') {
      this.setState({
        activeTool: {
          key: 1,
          name: 'Macronutrient Calculator',
          cardImg: MacronutrientIcon,
          cardSubtitle:
            'Get the perfect amount of calories, protein, carbs, and fat you should be eating every day.',
          active: true,
          about:
            'Discover your ideal macronutrient intake for building muscle, losing fat, and increasing strength with the Alvaro Fitness Calculator. Determine how much protein, carbs, and fats you should eat every day to achieve your fitness goals.',
        },
      });
    } else if (
      this.props.location.pathname === '/tools/one-rep-max-calculator'
    ) {
      this.setState({
        activeTool: {
          key: 2,
          name: 'One Rep Max Calculator',
          cardImg: OneRepMaxIcon,
          cardSubtitle:
            'Calculate your one-repetition maximum (1RM) to optimize your strength training.',
          active: false,
          about:
            "Enhance your strength training regimen with the One Rep Max Calculator. Estimate your one-repetition maximum for various exercises, allowing you to customize your workout intensity and track your progress over time. Whether you're a beginner or an experienced lifter, this tool can help you reach your strength goals.",
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.macronutrientAnswers !== this.state.macronutrientAnswers) {
      this.calculateBMR(this.state.macronutrientAnswers, (bmr) => {
        // This function will be executed after BMR is calculated and state is updated.
        this.setState({
          results: { ...this.state.results, bmr: Math.round(bmr) },
        });
      });
    }
  }

  componentWillUnmount() {
    // Clean up event listener when component unmounts
    window.removeEventListener('message', this.handleMessage);
  }

  scrollToMyRef = () => {
    const node = this.myRef.current;
    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  handleIframeButtonClick = (tool) => {
    let slug;
    if (tool === 'tools') {
      slug = tool;
    } else {
      slug = tool.name.toLowerCase().split(' ').join('-');
    }
    console.log(slug);
    window.parent.postMessage({ type: 'updateRoute', data: slug }, '*');
  };

  handleMessage = (event) => {
    if (event.origin === 'https://www.alvarofitness.com') {
      if (event.data.type === 'updateRoute') {
        const route = event.data.data;
        // Use the route to navigate within your React app
        // For example, use React Router to navigate to the appropriate calculator
        this.props.navigate(`/tools/${route}`);
      }
    }
  };

  handleMeasurementSystem = (e) => {
    this.setState({ unit: e.target.value });
  };

  renderBreadCrumb = (tool) => {
    if (this.state.activeTool) {
      return (
        <div>
          <div className='breadcrumb'>
            <h4
              style={{ color: '#FFA726', cursor: 'pointer' }}
              onClick={() => {
                this.setState({ activeTool: null });
                this.props.clearSuccessMessage();
                this.props.navigate('/tools');
                this.handleIframeButtonClick('tools');
              }}
            >
              Tools/
            </h4>
            <h4>{tool.name}</h4>
          </div>
          <p className='subtitle-text mb-10'>{tool.about}</p>
        </div>
      );
    }
  };

  renderDropdown = (options, label, name) => {
    let selectedOption = options.filter(
      (option) => option.value === this.state.macronutrientAnswers[name]
    )[0];

    return (
      <div className='dropdown-container'>
        {label === 'Formula' ? (
          <div className='flex align-center'>
            <p className='label'>{label}</p>
            <Popover
              trigger='click'
              content={
                <p
                  style={{ fontSize: '14px', width: '100%', maxWidth: '100px' }}
                >
                  {selectedOption.explanation}
                </p>
              }
            >
              <QuestionCircleOutlined
                style={{ fontSize: '10px', margin: '0 0 5px 3px' }}
              />
            </Popover>
          </div>
        ) : (
          <p className='label'>{label}</p>
        )}
        <Select
          onSelect={(value) => this.handleDropdownValue(name, value)}
          className='dropdown custom-input'
          style={{ width: '100%' }}
          value={this.state.macronutrientAnswers[name]}
        >
          {options.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.name}
            </Option>
          ))}
        </Select>
        {name === 'goal' &&
          options.map(
            (option) =>
              option.value === this.state.macronutrientAnswers[name] && (
                <p
                  className='subtitle-text'
                  style={{ margin: '10px 0 0 10px' }}
                >
                  {option.explanation}
                </p>
              )
          )}
      </div>
    );
  };

  renderInputField = (label, name) => {
    return (
      <div className='input-container'>
        <p className='label'>{label}</p>
        <Input
          onChange={(e) => this.handleInputValue(name, e.target.value)}
          className='input custom-input'
          type='number'
          value={this.state.macronutrientAnswers[name]}
        />
      </div>
    );
  };

  setActiveTool = (tool) => {
    const { navigate } = this.props;
    this.setState({ activeTool: tool });
    navigate(`/tools/${tool.cardTitle.toLowerCase().split(' ').join('-')}`);
  };

  handleDropdownValue = (field, value) => {
    let macronutrientAnswers = { ...this.state.macronutrientAnswers };
    macronutrientAnswers[field] = value;
    this.setState({ macronutrientAnswers }, () => {
      // Wait for state update, then calculate BMR with the updated state
      this.calculateBMR(macronutrientAnswers, (bmr) => {
        this.setState({
          results: { ...this.state.results, bmr: Math.round(bmr) },
        });
      });
    });
  };

  handleInputValue = (field, value) => {
    let macronutrientAnswers = { ...this.state.macronutrientAnswers };
    macronutrientAnswers[field] = value;
    this.setState({ macronutrientAnswers }, () => {
      // Wait for state update, then calculate BMR with the updated state
      this.calculateBMR(macronutrientAnswers, (bmr) => {
        this.setState({
          results: { ...this.state.results, bmr: Math.round(bmr) },
        });
      });
    });
  };

  calculateBMR = (macroAnswers, callback) => {
    let feet, inches;
    if (macroAnswers.height) {
      [feet, inches] = macroAnswers.height.split("'");
      feet = Number(feet);
      inches = Number(inches);
    }
    let bmrFormula = macroAnswers.bmrFormula;
    let weight =
      macroAnswers.unit === 'imperial'
        ? Number(macroAnswers.weight) * 0.453592
        : macroAnswers.weight;
    let height =
      macroAnswers.unit === 'imperial'
        ? feetToCentimeters(feet, inches)
        : macroAnswers.height;
    let age = Number(macroAnswers.age);
    let lbm = weight * (1 - macroAnswers.bodyFatPercentage / 100);
    let bmr = 0;

    if (macroAnswers.gender === 'male') {
      if (bmrFormula === 'mifflin-st-jeor') {
        // Mifflin-St Jeor (Recommended):
        bmr = 10 * weight + 6.25 * height - 5 * age + 5;
      } else if (bmrFormula === 'harris-benedict') {
        // Harris-Benedict:
        bmr = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
      } else {
        // Katch-McArdle:
        bmr = 370 + 21.6 * lbm;
      }
    } else if (macroAnswers.gender === 'female') {
      if (bmrFormula === 'mifflin-st-jeor') {
        // Mifflin-St Jeor (Recommended):
        bmr = 10 * weight + 6.25 * height - 5 * age - 161;
      } else if (bmrFormula === 'harris-benedict') {
        // Harris-Benedict:
        bmr = 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
      } else {
        // Katch-McArdle:
        bmr = 370 + 21.6 * lbm;
      }
    }
    this.calculateCaloriesAndMacros(bmr, () => {
      // This function will be executed after calories state is updated.
      // Execute the callback function to pass the calculated BMR back to componentDidUpdate.
      if (typeof callback === 'function') {
        callback(bmr);
      }
    });
  };

  calculateCaloriesAndMacros = (bmr, callback) => {
    let activityFactor = this.state.macronutrientAnswers.activityFactor;
    let macroPreference = this.state.macronutrientAnswers.macroPreference;
    let goal = this.state.macronutrientAnswers.goal;
    let customMacros = this.state.macronutrientAnswers.customMacros;
    let protein = 0;
    let carbs = 0;
    let fat = 0;
    let calories = 0;
    let maintenanceCalories = bmr * activityFactor;

    if (goal === 'rapid-weight-loss') {
      calories = maintenanceCalories - 1000;
    } else if (goal === 'moderate-weight-loss') {
      calories = maintenanceCalories - 500;
    } else if (goal === 'slow-weight-loss') {
      calories = maintenanceCalories - 250;
    } else if (goal === 'maintain-weight') {
      calories = maintenanceCalories;
    } else if (goal === 'slow-weight-gain') {
      calories = maintenanceCalories + 250;
    } else if (goal === 'moderate-weight-gain') {
      calories = maintenanceCalories + 500;
    } else if (goal === 'rapid-weight-gain') {
      calories = maintenanceCalories + 1000;
    }

    // Calculate macronutrient ratios based on the selected goal
    switch (macroPreference) {
      case 'balanced':
        protein = (0.35 * calories) / 4;
        carbs = (0.35 * calories) / 4;
        fat = (0.3 * calories) / 9;
        break;
      case 'low-carb':
        protein = (0.4 * calories) / 4;
        carbs = (0.2 * calories) / 4;
        fat = (0.4 * calories) / 9;
        break;
      case 'high-carb':
        protein = (0.3 * calories) / 4;
        carbs = (0.5 * calories) / 4;
        fat = (0.2 * calories) / 9;
        break;
      case 'high-protein':
        protein = (0.4 * calories) / 4;
        carbs = (0.3 * calories) / 4;
        fat = (0.3 * calories) / 9;
        break;
      case 'ketogenic':
        protein = (0.4 * calories) / 4;
        carbs = (0.1 * calories) / 4;
        fat = (0.5 * calories) / 9;
        break;
      case 'custom':
        protein = ((customMacros.protein / 100) * calories) / 4;
        carbs = ((customMacros.carbs / 100) * calories) / 4;
        fat = ((customMacros.fat / 100) * calories) / 9;
        break;
      default:
        // If the goal is not recognized, use balanced as the default option
        protein = (0.35 * calories) / 4;
        carbs = (0.35 * calories) / 4;
        fat = (0.3 * calories) / 9;
    }

    this.setState(
      {
        results: {
          ...this.state.results,
          calories: Math.round(calories),
          maintenanceCalories: Math.round(maintenanceCalories),
          protein: Math.round(protein),
          carbs: Math.round(carbs),
          fat: Math.round(fat),
        },
      },
      () => {
        // After calories and macronutrients are updated in state, execute the callback function if provided
        if (typeof callback === 'function') {
          callback();
        }
      }
    );
  };

  setMacroPreference = (macroPreference) => {
    this.setState({
      macronutrientAnswers: {
        ...this.state.macronutrientAnswers,
        macroPreference,
        isCustomMacros: macroPreference === 'custom',
      },
    });
  };

  setCustomMacros = (macroType, percentage) => {
    const { customMacros } = this.state.macronutrientAnswers;

    let updatedMacros = { ...customMacros };
    updatedMacros[macroType] = percentage;

    // Calculate the remaining macro percentage
    const remainingPercentage =
      100 - (updatedMacros.protein + updatedMacros.carbs + updatedMacros.fat);

    console.log(!remainingPercentage === 0);

    this.setState(
      {
        macronutrientAnswers: {
          ...this.state.macronutrientAnswers,
          customMacros: updatedMacros,
        },
        percentageError: remainingPercentage === 0 ? false : true,
      },
      () => {
        // After updating state, recalculate grams and other values
        this.calculateCaloriesAndMacros(this.state.results.bmr, () => {
          // Optional: Handle any further logic after state is updated
        });
      }
    );
  };

  render() {
    return (
      <>
        <div className='tool-heading-container'>
          <div className='tool-heading-content'>
            <div>
              <h1>ELIMINATE NUTRITION GUESSWORK FOR GOOD</h1>
              <p>
                Discover your precise nutritional needs and get personalized
                hand portion serving calculations in just 3 simple steps.
              </p>

              <Button
                onClick={() => this.scrollToMyRef()}
                style={{ height: '50px' }}
                className='mt-30'
                type='primary'
                size='large'
              >
                Get Started
              </Button>
            </div>
            <img src={Alvaro} />
          </div>
        </div>
        <div className={this.state.activeTool ? 'tools-page' : ''}>
          <div ref={this.myRef} id='widgetContainer' className='tool'>
            <MacronutrientCalculatorComponent
              {...this.props}
              componentView={this.state.componentView}
              questionOptions={this.state.questionOptions}
              renderDropdown={this.renderDropdown}
              renderInputField={this.renderInputField}
              macronutrientAnswers={this.state.macronutrientAnswers}
              setMacroPreference={this.setMacroPreference}
              results={this.state.results}
              isCustomMacros={this.state.isCustomMacros}
              setCustomMacros={this.setCustomMacros}
              percentageError={this.state.percentageError}
              handleIframeButtonClick={this.handleIframeButtonClick}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearSuccessMessage: () =>
      dispatch({
        type: CLEAR_SUCCESS_MESSAGE,
        successMessage: null,
      }),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(MacronutrientCalculator));
