import {
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERROR,
  OPEN_REVIEW_MODAL,
  OPEN_LEGAL_MODAL,
  SET_FOOTER_NAVIGATION,
} from './constants';

export function sendEmail(emailData) {
  return {
    type: SEND_EMAIL,
    emailData,
  };
}

export function sendEmailSuccess() {
  return {
    type: SEND_EMAIL_SUCCESS,
  };
}

export function sendEmailError(err) {
  return {
    type: SEND_EMAIL_ERROR,
    err,
  };
}

export function openReviewModal(isOpen, review) {
  return {
    type: OPEN_REVIEW_MODAL,
    isOpen,
    review,
  };
}

export function openLegalModal(isLegalModalOpen, legalTopic) {
  return {
    type: OPEN_LEGAL_MODAL,
    isLegalModalOpen,
    legalTopic,
  };
}

export function setFooterNavigation(footerNavigation) {
  return {
    type: SET_FOOTER_NAVIGATION,
    footerNavigation,
  };
}
