import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { LandingHeader } from '../components/LandingPage/LandingHeader';
import ExerciseHistory from '../assets/images/ExerciseHistory.png';
import TelemundoLogo from '../assets/images/TelemundoLogo.png';
import { Button } from 'antd';
import Fide from '../assets/images/FideFront.png';
import Corey from '../assets/images/CoreyBack.png';
import Pritesh from '../assets/images/PriteshFront.png';
import { GoogleReviews } from '../components/GoogleReviews/index';
import { ReviewModal } from '../components/GoogleReviews/reviewModal';
import { openReviewModal } from '../ducks/Global/actions';
import NutritionPlan from '../assets/images/NutritionPlan.png';
import WorkoutPlan from '../assets/images/WorkoutPlan.png';
import ProgressTracking from '../assets/images/ProgressTracking.png';
import Framework from '../assets/images/AF-System.png';
import { faqData } from '../data/faq';
import { renderFaq } from '../components/Faq/index';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { RiDoubleQuotesR } from 'react-icons/ri';
import { starRating } from '../helpers/utils';
import { logEvent } from '../helpers/analytics';
import AlvaroCircle from '../assets/images/AlvaroCircle.png';
import AlvaroExercise from '../assets/images/AlvaroExercise-min.png';

export const HomeLanding = ({ sectionsRefs, handleScroll }) => {
  const isOpen = useSelector((state) => state.global.isOpen);
  const review = useSelector((state) => state.global.review);
  const featuredRef = useRef(null);

  const buttonStyle = {
    height: '50px',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '18px',
    transition: 'all 0.5s ease',
  };

  const problems = [
    {
      key: 1,
      // icon: WorkoutPlan,
      title: "I can't find the time to exercise.",
      icon: '❌',
      description:
        'As a busy professional, do you struggle to find time for exercise in your hectic schedule?',
      solution: [
        {
          icon: '⏱️',
          name: 'Efficient Workouts',
          description:
            'Your personalized workouts are designed to deliver maximum results in minimal time. Just three hours a week is all it takes.',
        },
        {
          icon: '📅',
          name: 'Flexible Scheduling',
          description:
            'I understand your busy life. Together, we’ll create a plan that fits seamlessly into your schedule, ensuring you stay on track every week.',
        },
      ],
    },
    {
      key: 2,
      // icon: WorkoutPlan,
      title:
        "I've worked with trainers but haven't achieved the results I want.",
      icon: '🫤',
      description:
        'Are you often unsure about which exercises and diets are best for your goals, leading to frustration and lack of progress?',
      solution: [
        {
          icon: '🏆',
          name: 'Proven Expertise',
          description:
            'With over 12 years of experience, I will give you clear, actionable guidance tailored to your goals, guaranteeing you’re always on the right track.',
        },
        {
          icon: '📊',
          name: 'Data Driven Approach',
          description:
            'Every rep, set, and progress point is tracked in my custom app, ensuring that every move you make is purposeful and progress-oriented.',
        },
      ],
    },
    {
      key: 3,
      // icon: WorkoutPlan,
      title: "I'm struggling to stay consistent and I need more accountability",
      icon: '😓',
      description:
        'Maintaining motivation and consistency can be challenging, especially with demanding careers and personal commitments.',
      solution: [
        {
          icon: '🤝',
          name: 'Next Level Accoutability',
          description:
            'I’m committed to keeping you motivated with regular check-ins, personalized support, and continuous progress tracking—ensuring you never fall off track.',
        },
        {
          icon: '💬',
          name: 'Suportive Community',
          description:
            'Join a network of like-minded professionals who uplift and motivate each other, helping you stay consistent and inspired.',
        },
      ],
    },
  ];

  const howItWorks = [
    {
      key: 1,
      // icon: WorkoutPlan,
      title: 'Strategy Call',
      subtitle:
        "We'll jump on a 30-minute call, chat about your current lifestyle, struggles, and goals. I will give you actionable advice to start making your fitness goals a reality based on your personal circumstances. This is NOT a sales call.",
    },
    {
      key: 2,
      // icon: AlternativeFoods,
      title: 'Sign Up',
      subtitle:
        "If I determine I can help you, you'll be offered a coaching spot and you can consider signing up for coaching. We'll get you started with the first step to your transformation: completing the assessment.",
    },
    {
      key: 3,
      // icon: AlternativeFoods,
      title: 'Prepare',
      subtitle:
        "Once the assessment is completed and I have all the information I need, I'll start working on your personalized program. This step takes around 48 hours to complete.",
    },
    {
      key: 4,
      // icon: AlternativeFoods,
      title: 'Start',
      subtitle:
        "When your program is ready, we'll have a 45-minute meeting and go through everything in great detail so we can start your transformation and get everything right from the very beginning of your program.",
    },
  ];

  const whoFor = [
    {
      key: 1,
      title: (
        <p className='small-title mb-10'>
          <span className='highlighted'>
            You've Had Bad Experiences With Trainers In The Past
          </span>
        </p>
      ),
      subtitle:
        "Tired of trainers who don't listen or help you achieve real results?",
      testimonial:
        'I’ve had 4 personal trainers before and no one is as focused or cared more about my results than him. He is always watching my form, coming up with new exercises to reach my fitness goals, and also pushes me with my diet. I highly recommend him.',
      client: 'Oscar N.',
      profession: 'Real State Agent',
    },
    {
      key: 2,
      title: (
        <p className='small-title mb-10'>
          <span className='highlighted'>You Feel Hopeless</span>
        </p>
      ),
      subtitle: 'Fed up with trying everything and still not seeing results?',
      testimonial:
        "I thought there was nothing I could do to be slim again until a friend recommended me to Alvaro. He helped me optimize both my diet and exercise routines, and in less than three months I was down over 10% body weight, and I've continued to lose and tone ever since!",
      client: 'Dr. Simi R.',
      profession: 'Anesthesiologist',
    },
    {
      key: 3,
      title: (
        <p className='small-title mb-10'>
          <span className='highlighted'>You Don't Trust Anyone To Care</span>
        </p>
      ),
      subtitle: 'Worried that no one will understand or prioritize your goals?',
      testimonial:
        'My body shape changed after working out for a few months. My chest, arms, back and shoulders are bigger, and stronger than ever. Adding Alvaro’s nutrition guide and simple cardio tricks has contributed to better sleep at night and more focus during the day. I have worked out with other trainers over the years but Alvaro understands and focuses on my goals every time we workout.',
      client: 'Steve W.',
      profession: 'Director, Lead Concepts',
    },
    {
      key: 4,
      title: (
        <p className='small-title mb-10'>
          <span className='highlighted'>You Are Tired Of Making Excuses</span>
        </p>
      ),
      subtitle: 'Frustrated with not taking action?',
      testimonial:
        "What I've quickly learned is that if you do not follow the plan he methodically lays out for whatever your end goal is, outside of the training sessions, you may want to take a bit more time to work on your mental commitment. Though, if you're ready for a true transformation, I would not advise looking at anyone other than this guy.",
      client: 'Corey S.',
      profession: 'Founder, Ignite Staffing',
    },
    {
      key: 5,
      title: (
        <p className='small-title mb-10'>
          <span className='highlighted'>
            You Still Haven't Achieved Life Changing Results
          </span>
        </p>
      ),
      subtitle: 'Tired of not achieving the results you really want?',
      testimonial:
        'I only have great things to say about Alvaro. When I first began training  with Alvaro, I lost 22 lbs of fat in only two and a half months. If you want results, do what I did. Show up and always practice exactly what he teaches you. Diet and exercise always go hand in hand, and he has superb knowledge of both.   He is challenging, but the training with the education are definitely worth it.',
      client: 'Dr. Kevin B.',
      profession: 'General Doctor & Entrepreneur',
    },
  ];

  const whatYouGet = [
    {
      key: 1,
      img: WorkoutPlan,
      title: '1. Personalization 🧬',
      problem: 'No more one-size-fits-all plans.',
      solution:
        'Get tailored workouts, nutrition, and supplement recommendations designed specifically for your body, goals, and lifestyle. I adapt your program as you progress, ensuring every step is aligned with your unique needs.',
    },
    {
      key: 2,
      img: NutritionPlan,
      title: '2. Technology 💻',
      problem: 'Leverage the power of my custom app for your transformation.',
      solution:
        'My cutting-edge app tracks your workouts, nutrition, and progress. With data-driven insights, we optimize your plan continuously, making your program as efficient and effective as possible.',
    },
    {
      key: 3,
      img: ExerciseHistory,
      title: '3. Accountability 🤝',
      problem: 'Stay on track with constant support.',
      solution:
        'With regular check-ins, progress updates, and personal feedback, I ensure you stay committed to your goals. Our partnership will keep you motivated and accountable, so you can see real, measurable results.',
    },
    {
      key: 4,
      img: ProgressTracking,
      title: '4. Adaptation 🔄',
      problem: 'Your plan evolves with you.',
      solution:
        'As your body changes, so does your plan. We continuously monitor your progress and make necessary adjustments to your workouts and nutrition, keeping you on the path to success and avoid plateaus.',
    },
  ];

  const twoPaths = [
    {
      title: "Don't Change Anything",
      icon: (
        <FaTimesCircle
          className='mr-10'
          style={{ color: '#D22F2F', marginTop: '5px' }}
        />
      ),
      subtitle: '',
      bulletPoints: [
        'Keep grinding with little to show for your hard work',
        'Keep telling yourself you can do it on your own',
        'Keep wasting time and money on things that have not worked',
      ],
    },
    {
      title: 'Book Your Free Strategy Call',
      icon: (
        <FaCheckCircle
          className='mr-10'
          style={{ color: '#2761f1', marginTop: '5px' }}
        />
      ),
      subtitle: 'Not a sales call. 100% value.',
      bulletPoints: [
        'Get clarity on how to reach your goals',
        'Get 100% personalized recommendations',
        'Start making real progress',
      ],
    },
  ];

  const caseStudies = [
    {
      key: 1,
      img: (
        <img
          src={Fide}
          className='transformation-img'
          alt="Fide's transformation"
          loading='lazy'
        />
      ),
      title: 'Weigh Loss',
      subtitle: 'Lose weight while toning.',
      plan: [
        {
          title: 'Problem',
          icon: <FaTimesCircle className='mr-5' style={{ color: '#D22F2F' }} />,
          text: 'Unsure about the right exercise and nutrition plan needed for sustainable weight loss. ',
        },

        {
          title: 'Result',
          icon: <FaCheckCircle className='mr-5' style={{ color: '#2761f1' }} />,
          text: (
            <span>
              <span className='bold '>Lost 43.5 pounds in 32 weeks</span>,
              toned, and increased strength. Experienced enhanced energy levels,
              improved sleep quality, and a significant boost in overall quality
              of life.
            </span>
          ),
        },
      ],
    },
    {
      key: 2,
      img: (
        <img
          className='transformation-img'
          src={Corey}
          alt="Corey's transformation"
          loading='lazy'
        />
      ),
      title: 'Muscle Gain',
      subtitle: 'Increase lean muscle mass and strength.',
      plan: [
        {
          title: 'Problem',
          icon: <FaTimesCircle className='mr-5' style={{ color: '#D22F2F' }} />,
          text: "Struggled to find the time and stay committed due to a busy work schedule and work-related stress. He was eager for change but didn't have a clear path and accountability to get there.",
        },
        {
          title: 'Result',
          icon: <FaCheckCircle className='mr-5' style={{ color: '#2761f1' }} />,
          text: (
            <span>
              <span className='bold'>
                Gained 24 pounds of muscle mass in 32 weeks
              </span>{' '}
              and significantly improved overall strength within months.
            </span>
          ),
        },
      ],
    },
    {
      key: 3,
      img: (
        <img
          src={Pritesh}
          className='transformation-img'
          alt="Pritesh's transformation"
          loading='lazy'
        />
      ),
      title: 'Body Recomposition',
      subtitle: 'Switch body fat for muscle.',
      plan: [
        {
          title: 'Problem',
          icon: <FaTimesCircle className='mr-5' style={{ color: '#D22F2F' }} />,
          text: 'As a Vegetarian, he struggled to get a balanced nutrition and supplementation plan that could allow him to switch fat for muscle. He tried other trainers but never got the results he wanted.',
        },
        {
          title: 'Result',
          icon: <FaCheckCircle className='mr-5' style={{ color: '#2761f1' }} />,
          text: (
            <span>
              <span className='bold'>Lost over 14% body fat in 16 weeks</span>{' '}
              while increasing muscle mass and strength, successfully staying
              around the same body weight.
            </span>
          ),
        },
      ],
    },
  ];

  return (
    <div className='landing-page'>
      <div ref={sectionsRefs['#home-section-0']}>
        <LandingHeader
          color={'rgb(240, 242, 255)'}
          title={
            <span>
              Transform The Way You
              <br /> Look And Feel in Just
              <br />
              16 weeks.
            </span>
            //  <span className='underlined light-blue'></span> <br />
          }
          subtitle={
            'My unique system will give you the results you are looking for - guaranteed.'
          }
          buttonStyle={buttonStyle}
          image={AlvaroExercise}
          btnText={'Book Your Free Strategy Call'}
          bulletPoints={[
            '1-on-1 training, accountability, and support',
            'Build muscle while shredding body fat',
            'Increase your energy and boost your confidence',
            '100% customized exercise, nutrition, and supplementation',
          ]}
          btnAction={() => {
            logEvent('Button', 'click', 'Free Strategy Call');
            window.open(
              'https://calendly.com/alvarofitness/consultation',
              '_blank'
            );
          }}
          textOverTitle={'BUSY PROFESSIONALS'}
        />
      </div>
      <div ref={featuredRef}>
        <div className='featured-banner'>
          <div className='featured-logo'>
            <p className='subtitle bold'>Featured on</p>
            <img
              src={TelemundoLogo}
              alt='Telemundo'
              style={{ height: '60px' }}
              loading='lazy'
            />
          </div>
        </div>
      </div>
      <div ref={sectionsRefs['#home-section-2']} className='section white'>
        <div className='content'>
          <div className='grid justify-center mb-5'>
            <p className='title mb-10' style={{ textAlign: 'center' }}>
              Break Free From Your{' '}
              <span className='text-gradient underlined-title'>
                Limiting Believes
              </span>
            </p>
            <p
              className='big-subtitle mb-50'
              style={{
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              My clients have overcome their challenges and achieved incredible
              transformations through my coaching.
            </p>
          </div>
          <div className='container'>
            <div className='three-column'>
              {problems.map((problem) => (
                <div className='global-card'>
                  <div className='grid justify-center'>
                    <span style={{ fontSize: '40px', textAlign: 'center' }}>
                      {problem.icon}
                    </span>
                    <div className='text'>
                      <p
                        className='subtitle bold mb-20'
                        style={{ textAlign: 'center', color: 'black' }}
                      >
                        "{problem.title}"
                      </p>
                    </div>
                  </div>

                  {problem.solution.map((solution) => (
                    <div>
                      <div
                        className='grid-bullet-point'
                        style={{ alignItems: 'center' }}
                      >
                        <span className='mr-5'>{solution.icon}</span>
                        <p
                          className='subtitle text-gradient bold'
                          style={{ color: 'black' }}
                        >
                          {solution.name}
                        </p>
                      </div>
                      <p className='subtitle mb-20'>{solution.description}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div ref={sectionsRefs['#home-section-2']} className='section gray'>
        <div className='content'>
          <div className='grid grid justify-center mb-20'>
            <p className='title mb-10' style={{ textAlign: 'center' }}>
              The Alvaro Fitness{' '}
              <span className='text-gradient underlined-title'>System</span>
            </p>
            <p
              className='big-subtitle mb-50'
              style={{
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              My 4 Unique Pillars For A Guaranteed Transformation.
            </p>
          </div>

          <div className='flex justify-center mb-100'>
            <img
              className='framework-img'
              src={Framework}
              alt={'Framework'}
              loading='lazy'
            />
          </div>

          {whatYouGet.map((item, index) => (
            <div>
              <div
                key={item.key}
                className={`what-you-get-item ${index % 2 === 1 ? 'even' : ''}`}
              >
                <img src={item.img} alt='Program Feature' loading='lazy' />
                <div className='text mt-20'>
                  <p className='small-title mb-5'>{item.title}</p>
                  <div>
                    <p className='subtitle text-gradient bold mb-10'>
                      {item.problem}
                    </p>
                    <p className='subtitle'>✅ {item.solution}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div style={{ textAlign: 'center' }}>
            <Button
              className='landing-button'
              style={buttonStyle}
              type='primary'
              onClick={() => {
                logEvent('Button', 'click', 'Free Strategy Call');
                window.open(
                  'https://calendly.com/alvarofitness/consultation',
                  '_blank'
                );
              }}
            >
              Book Your Free Strategy Call
              {/* <ArrowRightOutlined /> */}
            </Button>
            <p className='text-under-btn mt-5'>
              "If I could give 10 stars here, I would" - Corey
            </p>
          </div>
        </div>
      </div>

      <div ref={sectionsRefs['#home-section-2']} className='section white'>
        <div className='content'>
          <div className='grid grid justify-center'>
            <p className='title mb-50' style={{ textAlign: 'center' }}>
              So, Who Is{' '}
              <span className='text-gradient underlined-title'>This For?</span>
            </p>
          </div>

          {whoFor.map((item, index) => (
            <div>
              <div key={item.key} className='what-you-get-item'>
                <div className='highlighted-block'>
                  {item.title}
                  <p className='subtitle'>{item.subtitle}</p>
                </div>
                <div
                  className='global-card'
                  style={{
                    position: 'relative',
                  }}
                >
                  {starRating(5)}
                  <p
                    className='subtitle mb-10'
                    style={{ maxWidth: '450px', fontStyle: 'italic' }}
                  >
                    "{item.testimonial}"
                  </p>

                  <p className='subtitle bold'>{item.client}</p>
                  <p
                    className='subtitle'
                    style={{
                      fontSize: '14px',
                      color: 'gray',
                      lineHeight: '16px',
                    }}
                  >
                    {item.profession}
                  </p>

                  <RiDoubleQuotesR
                    style={{
                      fontSize: '60px',
                      color: '#dce0ff',
                      position: 'absolute',
                      right: '0px',
                      top: '-30px',
                    }}
                  />
                </div>
              </div>
            </div>
          ))}

          <div style={{ textAlign: 'center' }}>
            <Button
              className='landing-button'
              style={buttonStyle}
              type='primary'
              onClick={() => {
                logEvent('Button', 'click', 'Free Strategy Call');
                window.open(
                  'https://calendly.com/alvarofitness/consultation',
                  '_blank'
                );
              }}
            >
              Book Your Free Strategy Call
            </Button>
            <p className='text-under-btn mt-5'>
              "If I could give 10 stars here, I would" - Corey
            </p>
          </div>
        </div>
      </div>
      <div className='section gray'>
        <div className='content'>
          <div className='grid grid justify-center'>
            <p className='title mb-50' style={{ textAlign: 'center' }}>
              Meet{' '}
              <span className='text-gradient underlined-title'>Your Coach</span>
            </p>
          </div>
          <div className='grid justify-center' style={{ maxWidth: '650px' }}>
            <img
              className='mb-20'
              style={{ maxHeight: '200px' }}
              src={AlvaroCircle}
              alt='Alvaro'
              loading='lazy'
            />
            <div>
              <p className='small-title bold text-center mb-20'>
                👋 Hi, I'm Alvaro De La Torre...
              </p>
              <p className='subtitle mb-20'>
                And if you're a busy professional or entrepreneur struggling to
                find time to focus on your health and fitness...
              </p>
              <p className='subtitle mb-20'>
                {' '}
                Or if you're feeling frustrated because nothing you've tried has
                worked to help you achieve the body and confidence you want...
              </p>

              <p className='subtitle bold mb-20'>
                Then, I want to let you know you're in the right place.
              </p>
              <p className='subtitle mb-20'>
                What many overlook, including so-called "experts" on social
                media, is the real reason behind unsuccessful fitness and body
                transformations.
              </p>
              <p className='subtitle bold mb-20'>
                {' '}
                It boils down to one of two things (and often both):
              </p>
              <div className='mb-20'>
                <div className='grid-bullet-point'>
                  <span className='mr-10'>👉</span>
                  <p className='subtitle'>
                    {' '}
                    Not identifying and addressing the root cause of your
                    challenges
                  </p>
                </div>
                <div className='grid-bullet-point'>
                  <span className='mr-10'>👉</span>
                  <p className='subtitle'>
                    {' '}
                    Not following a personalized plan that's tailored to your
                    unique needs and lifestyle
                  </p>
                </div>
              </div>
              <p className='subtitle mb-20'>
                Everything you've tried so far has fallen short because it
                either didn't get to the heart of the problem or wasn't the
                right fit for you.
              </p>
              <p className='subtitle bold mb-20'> But today, that changes.</p>
              <p className='subtitle mb-20'>
                {' '}
                With a transformation program tailored just for you, I promise
                you're going to have new hope. Hope that maybe you thought was
                lost forever.
              </p>
              <p className='subtitle bold mb-20'>
                This isn't about quick fixes or cookie-cutter solutions...{' '}
              </p>
              <p className='subtitle mb-20'>
                {' '}
                It's a deep dive into your unique fitness journey and crafting a
                strategy to achieve real results.
              </p>
              <p className='subtitle mb-20'>
                Imagine starting your day with more energy, feeling confident
                about your body, and enjoying the activities you love without
                any stress or fatigue...
              </p>
              <p className='subtitle mb-20'>
                Picture yourself at work or social events, feeling confident and
                proud of your body and the progress you've made...
              </p>
              <p className='subtitle mb-20'>
                Or imagine never having to invent excuses again for avoiding the
                gym or healthy habits...
              </p>
              <p className='subtitle mb-20'>
                This is what happens when you tackle fitness with a plan made
                just for you.
              </p>
              <p className='subtitle mb-20'>
                You don't need another one-size-fits-all solution.
              </p>
              <p className='subtitle mb-20'>You've been there, done that.</p>
              <p className='subtitle mb-20'>
                {' '}
                What you need is a breakthrough that understands the real you –
                and gets you back to feeling your best, confident, and strong.
              </p>
              <p className='subtitle mb-20'>
                {' '}
                It might sound too good to be true, but trust me, it's real.
              </p>
              <p className='subtitle mb-20'>
                {' '}
                My approach is backed by science, years of experience, and
                countless success stories of people who were once in your
                position.
              </p>
              <p className='subtitle mb-20'>
                {' '}
                Are you ready to transform your body, boost your confidence, and
                rediscover the person you are meant to be?
              </p>
              <p className='subtitle bold mb-20'>
                {' '}
                Then I invite you to book your Free Strategy Call
              </p>
              <p className='subtitle mb-20'>
                {' '}
                I'll analyze your current situation, and will give you
                actionable advice so you can start looking and feeling your
                best.
              </p>
              <p className='subtitle'> I look forward to meeting you!</p>
            </div>
          </div>

          <div style={{ textAlign: 'center' }}>
            <Button
              className='landing-button mt-50'
              style={buttonStyle}
              type='primary'
              onClick={() => {
                logEvent('Button', 'click', 'Free Strategy Call');
                window.open(
                  'https://calendly.com/alvarofitness/consultation',
                  '_blank'
                );
              }}
            >
              Book Your Free Strategy Call
              {/* <ArrowRightOutlined /> */}
            </Button>
            <p className='text-under-btn mt-5'>
              "If I could give 10 stars here, I would" - Corey
            </p>
          </div>
        </div>
      </div>
      <div ref={sectionsRefs['#home-section-4']} className='section white'>
        <div className='content'>
          <div className='grid justify-center mb-50'>
            <p className='title mb-10' style={{ textAlign: 'center' }}>
              What Clients{' '}
              <span className='text-gradient underlined-title'>
                Have To Say
              </span>
            </p>
            <p
              className='big-subtitle'
              style={{
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              See More 5-Star Reviews on Google.
            </p>
          </div>
          <div className='container'>
            <ReviewModal
              isOpen={isOpen}
              openReviewModal={openReviewModal}
              review={review}
            />
            <GoogleReviews color={'#dce0ff'} />
          </div>
        </div>
      </div>
      <div
        ref={sectionsRefs['#home-section-3']}
        id='home-section-2'
        className='section gray'
      >
        <div className='content'>
          <div className='grid grid justify-center mb-50'>
            <p className='title mb-10' style={{ textAlign: 'center' }}>
              What's{' '}
              <span className='text-gradient underlined-title'>Next?</span>
            </p>
            <p
              className='big-subtitle mb-50'
              style={{
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              The Roadmap to Your New Self.
            </p>
            {howItWorks.map((step, i) => (
              <div className='how-it-works-card'>
                <div className='title text-gradient mb-5'>{i + 1}</div>
                <h3 className='small-title mb-20'>{step.title}</h3>
                <p className='subtitle'>{step.subtitle}</p>
              </div>
            ))}
            <Button
              className='landing-button mt-50'
              style={buttonStyle}
              type='primary'
              onClick={() => {
                logEvent('Button', 'click', 'Free Strategy Call');
                window.open(
                  'https://calendly.com/alvarofitness/consultation',
                  '_blank'
                );
              }}
            >
              Book Your Free Strategy Call
            </Button>
            <p className='text-under-btn mt-5'>
              "I’ve had 4 personal trainers before and no one is as focused or
              cared more about my results than him." - Oskar
            </p>
          </div>
        </div>
      </div>
      <div ref={sectionsRefs['#home-section-5']} className='section white'>
        <div className='content'>
          <div className='grid grid justify-center mb-5'>
            <p className='title mb-10' style={{ textAlign: 'center' }}>
              Case{' '}
              <span className='text-gradient underlined-title'>Studies</span>
            </p>
            <p
              className='big-subtitle mb-50'
              style={{
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
              }}
            >
              Weight Loss - Muscle Gain - Body Recomposition
            </p>
          </div>

          {caseStudies.map((c, index) => (
            <div key={c.key} className='what-you-get-item'>
              {c.img}
              <div style={{ maxWidth: '400px' }}>
                <div className='mb-20'>
                  <p className='small-title'>{c.title}</p>
                  <p className='subtitle text-gradient bold'>{c.subtitle}</p>
                </div>

                {c.plan.map((p) => (
                  <div>
                    <div className='text-center-mobile flex align-center mb-5'>
                      {p.icon}
                      <p
                        className='small-title'
                        style={{ fontSize: '16px', lineHeight: '22px' }}
                      >
                        {p.title}
                      </p>
                    </div>
                    <div className='flex mb-20'>
                      <p className='subtitle'> {p.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div style={{ textAlign: 'center' }}>
            <p
              className='small-title'
              style={{
                fontStyle: 'italic',
                fontSize: '24px',
              }}
            >
              Be The Next Success Story!
            </p>
            <Button
              className='landing-button mt-20'
              style={buttonStyle}
              type='primary'
              onClick={() => {
                logEvent('Button', 'click', 'Free Strategy Call');
                window.open(
                  'https://calendly.com/alvarofitness/consultation',
                  '_blank'
                );
              }}
            >
              Book Your Free Strategy Call
            </Button>

            <p className='text-under-btn mt-5'>
              "Hands down the best personal trainer I've ever had, and I've had
              several over the years!" - Simi
            </p>
          </div>
        </div>
      </div>
      <div ref={sectionsRefs['#home-section-2']} className='section gray'>
        <div className='content'>
          <div className='grid grid justify-center mb-50'>
            <p className='title' style={{ textAlign: 'center' }}>
              Now You Have 2 Paths{' '}
              <span className='text-gradient underlined-title'>
                In Front Of You...
              </span>
            </p>
          </div>

          <div className='benefits'>
            {twoPaths.map((path, i) => (
              <div className='global-card'>
                <div className='grid justify-center'>
                  <div className='title text-gradient mb-5'>{i + 1}</div>
                  <p
                    className={`small-title text-center ${
                      path.subtitle ? 'mb-10' : 'mb-20'
                    }`}
                  >
                    {path.title}
                  </p>
                  {path.subtitle && (
                    <p className='subtitle mb-20'>{path.subtitle}</p>
                  )}
                </div>
                {path.bulletPoints.map((point) => (
                  <div className='grid-bullet-point'>
                    {path.icon}
                    <p className='subtitle'>{point}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div style={{ textAlign: 'center' }}>
            <Button
              className='landing-button mt-50'
              style={buttonStyle}
              type='primary'
              onClick={() => {
                logEvent('Button', 'click', 'Free Strategy Call');
                window.open(
                  'https://calendly.com/alvarofitness/consultation',
                  '_blank'
                );
              }}
            >
              Book Your Free Strategy Call
            </Button>
            <p className='text-under-btn mt-5'>
              "If I could give 10 stars here, I would" - Corey
            </p>
          </div>
        </div>
      </div>
      <div
        ref={sectionsRefs['#home-section-7']}
        className='content centered-container'
      >
        <div className='max-width-container mt-50'>
          {renderFaq(faqData.onsiteProgramFaq, null, true)}
        </div>
      </div>
    </div>
  );
};
