export const footerLinksConfig = {
  '/program-recommendation/results': [
    { key: 1, name: 'Features', link: '#home-section-1' },
    { key: 2, name: 'Testimonials', link: '#home-section-2' },
    { key: 3, name: 'The Program', link: '#home-section-3' },
    { key: 4, name: 'How It Works', link: '#home-section-4' },
    { key: 5, name: 'Success Stories', link: '#home-section-5' },
    { key: 6, name: 'Pricing', link: '#home-section-6' },
    { key: 7, name: 'FAQ', link: '#home-section-7' },
  ],
  '/': [
    { key: 1, name: 'Header', link: '#home-section-0' },
    { key: 2, name: 'Choose Your Path', link: '#home-section-1' },
    { key: 3, name: 'What To Expect', link: '#home-section-3' },
    { key: 4, name: 'About Your Program', link: '#home-section-2' },
    { key: 5, name: 'Testimonials', link: '#home-section-4' },
    { key: 6, name: 'Success Stories', link: '#home-section-5' },
    { key: 7, name: 'FAQ', link: '#home-section-6' },
  ],
};
