import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getOrders, deleteOrder } from '../ducks/Orders/actions';
import OrdersPage from '../presentational/Orders';
import { createOrderDetail, getOrdersDetail, resetCreateOrderDetailSuccess } from '../ducks/OrdersDetail/actions';
import { toast } from 'react-toastify';
import { Spin } from 'antd';

class Orders extends React.Component {

  componentDidMount(){
    this.props.getOrders(); 
    this.props.getOrdersDetail();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.createOrderDetailSuccess && this.props.createOrderDetailSuccess) {
      this.renderToast();
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

      this.props.resetCreateOrderDetailSuccess();
    }
  }

  renderToast = () => {
    toast.success('Order detail created!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  render(){
    return (
      <div className='orders-page'>
        {this.props.orders ? <OrdersPage {...this.props}/> : <Spin />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    orders: state.orders.orders,
    ordersDetail: state.ordersDetail.ordersDetail,
    createOrderDetailSuccess: state.ordersDetail.createOrderDetailSuccess
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOrders: () => dispatch(getOrders()),
    deleteOrder: (id) => dispatch(deleteOrder(id)),
    createOrderDetail: (ingredients, totalMeals, totalCost, orderFromTo) => dispatch(createOrderDetail(ingredients, totalMeals, totalCost, orderFromTo)),
    getOrdersDetail: () => dispatch(getOrdersDetail()),
    resetCreateOrderDetailSuccess: () => dispatch(resetCreateOrderDetailSuccess())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);