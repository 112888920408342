export const questionnaire = {
  goal: {
      key: 'goal',
      label: 'My primary goal is to',
      options: [
          {key: 'lose', text: "Lose Body Fat"},
          {key: 'gain', text: "Gain Muscle"}
      ],
      selected_option: null 
  },
  access_to_equipment: {
      key: 'equipment',
      label: 'I have access to',
      options: [
          {key: 'gym', text: "A fully equipped gym"},
          {key: 'dumbbells', text: "Some dumbbells"}
      ],
      selected_option: null
  },
//   help_needed: {
//       key: 'help',
//       label: 'I need help with',
//       options: [
//           {key: 'all', text: "A complete workout and nutrition plan"},
//           {key: 'workout', text: "A workout plan"},
//           {key: 'nutrition', text: "A nutrition plan"}
//       ],
//       selected_option: null
//   },
  gender: {
      key: 'gender',
      label: 'My biological gender is',
      options: [
          {key: 'male', text: "Male"},
          {key: 'female', text: "Female"}
      ],
      selected_option: null
  },
  age_group: {
      key: 'age',
      label: 'I am in my',
      options: [
          {key: 'twenties', text: "Twenties"},
          {key: 'thirties', text: "Thirties"},
          {key: 'forties', text: "Forties"},
          {key: 'over-fifty', text: "Over Fifty"}
      ],
      selected_option: null
  },
  experience_level: {
      key: 'experience',
      label: 'My experience level is',
      options: [
          {key: 'beginner', text: "Beginner"},
          {key: 'intermediate', text: "Intermediate"}
      ],
      selected_option: null
  }
}
